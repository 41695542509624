var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.open
    ? _c(
        "a-form-model",
        {
          ref: "form",
          staticClass: "form-list",
          attrs: { model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "车端任务名称", prop: "vehicleTaskId" } },
            [_vm._v(" " + _vm._s(_vm.form.taskName) + " ")]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务路径类型", prop: "vehicleTaskId" } },
            [_vm._v(" " + _vm._s(_vm.taskTypeFormat(_vm.form.taskType)) + " ")]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务完成进度", prop: "taskProgress" } },
            [_vm._v(" " + _vm._s(_vm.form.taskProgress) + "% ")]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务完成时长", prop: "vehicleTaskId" } },
            [
              _vm._v(
                " " +
                  _vm._s((Number(_vm.form.finishedTime) / 60).toFixed(2)) +
                  "分钟 "
              ),
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务开始时间", prop: "taskStartTime" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.parseDateTime(
                      new Date(_vm.form.vehicleStartTime * 1000),
                      "yyyy-MM-dd HH:mm:ss"
                    )
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务结束时间", prop: "taskEndTime" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.parseDateTime(
                      new Date(_vm.form.vehicleEndTime * 1000),
                      "yyyy-MM-dd HH:mm:ss"
                    )
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "任务完结类型", prop: "state" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.taskFinishedFlagObj[_vm.form.taskFinishedFlag + ""]
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "轨迹概览", prop: "taskState" } },
            [
              _c("track-page", {
                staticStyle: { width: "50vw", height: "50vh" },
                attrs: { trackData: _vm.trackData },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              staticClass: "track-detail",
              attrs: { label: " ", prop: "taskState" },
            },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleNavigateTo(_vm.form)
                    },
                  },
                },
                [_vm._v("轨迹详情")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }